import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  .card {\n    padding: 24px;\n\n    .top {\n      display: flex;\n      align-items: center;\n      gap: 8px;\n    }\n\n    .fullname {\n      margin: 0;\n      font-family: ",
        ";\n      font-weight: 700;\n      ",
        ";\n      color: ",
        ";\n    }\n\n    #ch {\n      display: none;\n    }\n\n    p {\n      font-family: ",
        ";\n      font-weight: 400;\n      ",
        ";\n      color: ",
        ";\n      margin: 0;\n      margin-top: 16px;\n      margin-bottom: 8px;\n    }\n\n    .content {\n      display: none;\n    }\n\n    #ch:checked ~ .content {\n      display: block;\n      -webkit-animation: fadeIn 0.4s;\n      animation: fadeIn 0.4s;\n    }\n\n    #ch:checked ~ label {\n      display: none;\n    }\n\n    #ch:checked ~ .smallText {\n      display: none;\n    }\n\n    label {\n      font-family: ",
        ";\n      font-weight: 700;\n      ",
        ";\n      color: ",
        ";\n      cursor: pointer;\n    }\n\n    @-webkit-keyframes fadeIn {\n      from {\n        opacity: 0;\n      }\n      to {\n        opacity: 1;\n      }\n    }\n    @keyframes fadeIn {\n      from {\n        opacity: 0;\n      }\n      to {\n        opacity: 1;\n      }\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  width: 32px;\n  height: 32px;\n  border-radius: 32px;\n  background: url(",
        "), lightgray -11.818px 0px / 186.916% 112.5% no-repeat;\n  background-size: cover;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var SeeMoreContent = styled.div.withConfig({
    componentId: "sc-52346929-0"
})(_templateObject(), fonts.regular, pxToCssFont(16, 22), colors.$ffffff, fonts.regular, pxToCssFont(16, 22), colors.$ffffff, fonts.regular, pxToCssFont(16, 22), colors.$ffffff);
export var RoundedImage = styled.div.withConfig({
    componentId: "sc-52346929-1"
})(_templateObject1(), function(param) {
    var img = param.img;
    return img;
});
