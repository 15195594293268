import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  .storyContent {\n    width: 100%;\n    max-height: 100%;\n    margin: auto;\n  },\n  .text: {\n    text-align: center;\n    color: white;\n    width: 90%;\n    margin: auto;\n  },\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var DefaultStyled = styled.div.withConfig({
    componentId: "sc-49a75c63-0"
})(_templateObject());
