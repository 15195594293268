import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: ",
        ";\n  height: ",
        ";\n  flex-direction: column;\n  justify-content: flex-end;\n  align-items: flex-start;\n  gap: 132px;\n  border-radius: 20px;\n  position: relative;\n  overflow: hidden;\n  div:first-child {\n    width: 100%;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: ",
        ";\n  height: ",
        ";\n  flex-direction: column;\n  justify-content: flex-end;\n  align-items: flex-start;\n  gap: 132px;\n  border-radius: 20px;\n  position: relative;\n  overflow: hidden;\n  div:first-child {\n    width: 100%;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  padding: 0 24px 24px 24px;\n  flex-direction: column;\n  justify-content: flex-end;\n  align-items: flex-start;\n  gap: 8px;\n  align-self: stretch;\n  width: 100%;\n  position: absolute;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  padding: 0 32px 32px 32px;\n  flex-direction: column;\n  justify-content: flex-end;\n  align-items: flex-start;\n  gap: 8px;\n  align-self: stretch;\n  width: 100%;\n  position: absolute;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n  /* Overflow after 3 lines */\n  overflow-wrap: break-word;\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n  /* Overflow after 3 lines */\n  overflow-wrap: break-word;\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  width: 56px;\n  height: 56px;\n  position: absolute;\n  top: 20px;\n  left: 20px;\n  display: flex;\n  align-content: center;\n  justify-content: center;\n  align-items: center;\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  width: 48px;\n  height: 48px;\n  border-radius: 48px;\n  flex-shrink: 0;\n  background: url(",
        "), lightgray -17.727px 0px / 186.916% 112.5% no-repeat;\n  background-size: cover;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  img {\n    width: 100% !important;\n    height: 100% !important;\n  }\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var CardContainerSmall = styled.div.withConfig({
    componentId: "sc-646b1b73-0"
})(_templateObject(), function(param) {
    var ignoreSize = param.ignoreSize;
    return ignoreSize ? "100%" : "296px";
}, function(param) {
    var ignoreSize = param.ignoreSize;
    return ignoreSize ? "100%" : "356px";
});
export var CardContainerLarge = styled.div.withConfig({
    componentId: "sc-646b1b73-1"
})(_templateObject1(), function(param) {
    var ignoreSize = param.ignoreSize;
    return ignoreSize ? "100%" : "405px";
}, function(param) {
    var ignoreSize = param.ignoreSize;
    return ignoreSize ? "100%" : "600px";
});
export var ContentContainerSmall = styled.div.withConfig({
    componentId: "sc-646b1b73-2"
})(_templateObject2());
export var ContentContainerLarge = styled.div.withConfig({
    componentId: "sc-646b1b73-3"
})(_templateObject3());
export var Title = styled.div.withConfig({
    componentId: "sc-646b1b73-4"
})(_templateObject4(), fonts.exbold, pxToCssFont(22, 32), colors.$ffffff);
export var TitleLarge = styled.div.withConfig({
    componentId: "sc-646b1b73-5"
})(_templateObject5(), fonts.exbold, pxToCssFont(28, 36), colors.$ffffff);
export var Topic = styled.div.withConfig({
    componentId: "sc-646b1b73-6"
})(_templateObject6(), fonts.regular, pxToCssFont(16, 22), colors.$ffffff);
export var TopicLarge = styled.div.withConfig({
    componentId: "sc-646b1b73-7"
})(_templateObject7(), fonts.regular, pxToCssFont(18, 24), colors.$ffffff);
export var Creator = styled.div.withConfig({
    componentId: "sc-646b1b73-8"
})(_templateObject8(), fonts.regular, pxToCssFont(16, 22), colors.$ffffff);
export var CreatorLarge = styled.div.withConfig({
    componentId: "sc-646b1b73-9"
})(_templateObject9(), fonts.regular, pxToCssFont(16, 22), colors.$ffffff);
export var EllipseContainer = styled.div.withConfig({
    componentId: "sc-646b1b73-10"
})(_templateObject10());
export var Ellipse = styled.div.withConfig({
    componentId: "sc-646b1b73-11"
})(_templateObject11(), function(param) {
    var img = param.img;
    return img;
});
