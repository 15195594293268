import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  /* stylelint-disable-next-line value-no-vendor-prefix */\n  height: -webkit-fill-available;\n  justify-content: space-between;\n  font-weight: 400;\n  font-family: ",
        ";\n  color: ",
        ";\n  text-align: left;\n  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */\n  height: 100%;\n\n  @media (min-width: ",
        ") {\n    padding: 8px 0 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-bottom: 32px;\n\n  @media (min-width: ",
        ") {\n    text-align: ",
        ";\n    margin-bottom: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    text-align: left;\n    ",
        "\n    margin-bottom: 32px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-bottom: 32px;\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n    margin-bottom: 32px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0 auto;\n    font-family: ",
        ";\n    ",
        "\n    text-align: left;\n\n    @media (min-width: ",
        ") {\n      text-align: center;\n      ",
        "\n    }\n\n    @media (min-width: ",
        ") {\n      text-align: left;\n    }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  justify-content: center;\n  // position: ",
        ";\n\n  div {\n    width: 100%;\n    max-width: inherit;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-top: 40px;\n    flex-direction: row;\n    gap: 30px;\n\n    div {\n      width: 100%;\n      max-width: 258px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      width: 100%;\n      max-width: 296px;\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n  margin: 32px;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  overflow: ",
        ";\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-b4260d65-0"
})(_templateObject(), fonts.exbold, colors.$262626, breakpoints.tablet);
export var Title = styled.div.withConfig({
    componentId: "sc-b4260d65-1"
})(_templateObject1(), pxToCssFont(30, 38), breakpoints.tablet, function(param) {
    var textAlign = param.textAlign;
    return textAlign || "center";
}, breakpoints.desktop, pxToCssFont(36, 45));
export var TitleCentered = styled.div.withConfig({
    componentId: "sc-b4260d65-2"
})(_templateObject2(), pxToCssFont(30, 38), breakpoints.tablet, breakpoints.desktop, pxToCssFont(36, 45));
export var Description = styled.div.withConfig({
    componentId: "sc-b4260d65-3"
})(_templateObject3(), fonts.regular, pxToCssFont(20, 26), breakpoints.tablet, pxToCssFont(18, 24), breakpoints.desktop);
export var ButtonSlideContainer = styled.div.withConfig({
    componentId: "sc-b4260d65-4"
})(_templateObject4(), function(param) {
    var isSticky = param.isSticky;
    return isSticky ? "sticky" : "static";
}, breakpoints.tablet, breakpoints.desktop);
export var TextContainer = styled.div.withConfig({
    componentId: "sc-b4260d65-5"
})(_templateObject5());
export var ImageContainer = styled.div.withConfig({
    componentId: "sc-b4260d65-6"
})(_templateObject6());
export var TopContainer = styled.div.withConfig({
    componentId: "sc-b4260d65-7"
})(_templateObject7(), function(param) {
    var isButtonSticky = param.isButtonSticky;
    return isButtonSticky ? "auto" : "unset";
});
