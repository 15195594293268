import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  gap: ",
        ";\n  margin: 0;\n  padding: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { pxToRem } from "@vfit/shared/themes";
export var Ul = styled.ul.withConfig({
    componentId: "sc-b2464a30-0"
})(_templateObject(), pxToRem(12), pxToRem(4, 0));
