import { useState } from "react";
import { getItemCookie, setItemCookie } from "@vfit/shared/data-access";
/**
 *
 * @param {String} key The key to store our data to
 * @param {String} defaultValue The default value to return in case the cookie doesn't exist
 */ export var useCookie = function(key, defaultValue) {
    var getCookie = function() {
        return getItemCookie(key) || defaultValue;
    };
    var ref = useState(getCookie()), cookie = ref[0], setCookie = ref[1];
    var updateCookie = function(value, numberOfDays) {
        setCookie(value);
        setItemCookie(key, value, numberOfDays);
    };
    return [
        cookie,
        updateCookie
    ];
};
export default useCookie;
