import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  .swiper-initialized {\n    overflow: visible;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  ",
        ";\n  font-family: ",
        ";\n  font-weight: 400;\n  b,\n  strong {\n    font-family: ",
        ";\n    font-weight: 400;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { fonts, pxToCssFont } from "@vfit/shared/themes";
export var StoryArticleContainer = styled.div.withConfig({
    componentId: "sc-a1051a22-0"
})(_templateObject());
export var Title = styled.h3.withConfig({
    componentId: "sc-a1051a22-1"
})(_templateObject1(), pxToCssFont(36, 48), fonts.light, fonts.exbold);
