import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Hydrate } from 'react-query';
import QueryProvider, {
  ModalIframeWrapper,
  ModalLeadWrapper,
  TrackingContextProvider,
  ModalErrorWrapper,
} from '@vfit/shared/providers';
import { ReactQueryDevtools } from 'react-query/devtools';
// eslint-disable-next-line import/no-relative-packages
import '../../../libs/shared/themes/src/lib/sunrise/styles/globals.css';
import { AppDynamics, HeadSeo } from '@vfit/shared/components';
import { StoryModalWrapper } from '@vfit/stories/stories';
import '../styles/carousel/offerCarousel.scss';
import 'swiper/css';
import 'swiper/css/pagination';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactNode) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const DefaultApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const [loading, setLoading] = useState<boolean>(true);
  const getLayout = Component.getLayout ?? ((page) => page);
  const { elements } = pageProps?.page?.params || {};
  const { seo } = elements || {};

  useEffect(() => {
    setLoading(false);
  }, []);

  const loadingComponent = () => <div />;

  return (
    <>
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://assets.sitescdn.net/answers-search-bar/v1.2/answers.css"
        />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="release-version" content="1.10.60" />
      </Head>
      <AppDynamics isConfig />
      <QueryProvider>
        <Hydrate state={pageProps.dehydratedState}>
          <TrackingContextProvider>
            <AppDynamics />
            <ModalErrorWrapper />
            <ModalIframeWrapper />
            <StoryModalWrapper />
            <ModalLeadWrapper />
            <ReactQueryDevtools initialIsOpen={false} />
            <HeadSeo {...seo} />
            {loading && loadingComponent()}
            {!loading && getLayout(<Component {...pageProps} />)}
          </TrackingContextProvider>
        </Hydrate>
      </QueryProvider>
    </>
  );
};

export default DefaultApp;
