export var Increment;
(function(Increment) {
    Increment[Increment["MOBILE"] = 1] = "MOBILE";
    Increment[Increment["TABLET"] = 2] = "TABLET";
    Increment[Increment["DESKTOP"] = 4] = "DESKTOP";
})(Increment || (Increment = {}));
export var DefaultMax;
(function(DefaultMax) {
    DefaultMax[DefaultMax["MOBILE"] = 6] = "MOBILE";
    DefaultMax[DefaultMax["TABLET"] = 8] = "TABLET";
    DefaultMax[DefaultMax["DESKTOP"] = 12] = "DESKTOP";
})(DefaultMax || (DefaultMax = {}));
