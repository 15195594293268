import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 4;\n  background-color: #000000c4;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  top: 55px;\n  right: 24px;\n  z-index: 2;\n  .button-close {\n    width: 36px;\n    height: 36px;\n  }\n  @media (min-width: ",
        ") {\n    top: 32px;\n    right: 32px;\n    .button-close {\n      width: 50px;\n      height: 50px;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    top: 40px;\n    right: 40px;\n    .button-close {\n      width: 50px;\n      height: 50px;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var Background = styled.div.withConfig({
    componentId: "sc-cffcac5c-0"
})(_templateObject());
export var CloseButton = styled.div.withConfig({
    componentId: "sc-cffcac5c-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
