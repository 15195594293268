import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: ",
        ";\n  height: ",
        ";\n  padding-bottom: 0;\n  flex-direction: column;\n  align-items: flex-start;\n  border-radius: 20px;\n  position: relative;\n  border: 1px solid ",
        ";\n  background: ",
        ";\n  gap: 24px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: ",
        ";\n  height: ",
        ";\n  padding-bottom: 0;\n  flex-direction: column;\n  align-items: flex-start;\n  border-radius: 20px;\n  position: relative;\n  border: 1px solid ",
        ";\n  background: ",
        ";\n  gap: 24px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  height: ",
        ";\n  background: lightgray;\n  border-radius: 20px 20px 0 0;\n  align-self: stretch;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  height: ",
        ";\n  background: lightgray;\n  border-radius: 20px 20px 0 0;\n  align-self: stretch;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  padding: 0 24px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 4px;\n  width: 100%;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  padding: 0 32px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 4px;\n  width: 100%;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n  /* Overflow after 3 lines */\n  overflow-wrap: break-word;\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n  /* Overflow after 3 lines */\n  overflow-wrap: break-word;\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        ";\n  color: ",
        ";\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  position: absolute;\n  top: 20px;\n  left: 20px;\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var CardContainerSmall = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-0"
})(_templateObject(), function(param) {
    var ignoreSize = param.ignoreSize;
    return ignoreSize ? "100%" : "296px";
}, function(param) {
    var ignoreSize = param.ignoreSize;
    return ignoreSize ? "100%" : "356px";
}, colors.$bebebe, colors.$ffffff);
export var CardContainerLarge = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-1"
})(_templateObject1(), function(param) {
    var ignoreSize = param.ignoreSize;
    return ignoreSize ? "100%" : "405px";
}, function(param) {
    var ignoreSize = param.ignoreSize;
    return ignoreSize ? "100%" : "600px";
}, colors.$bebebe, colors.$ffffff);
export var TopImageContainer = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-2"
})(_templateObject2(), function(param) {
    var ignoreSize = param.ignoreSize;
    return ignoreSize ? "50%" : "160px";
});
export var TopImageContainerLarge = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-3"
})(_templateObject3(), function(param) {
    var ignoreSize = param.ignoreSize;
    return ignoreSize ? "50%" : "348px";
});
export var ContentContainer = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-4"
})(_templateObject4());
export var ContentContainerLarge = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-5"
})(_templateObject5());
export var Title = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-6"
})(_templateObject6(), fonts.exbold, pxToCssFont(22, 32), colors.$262626);
export var TitleLarge = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-7"
})(_templateObject7(), fonts.exbold, pxToCssFont(28, 36), colors.$262626);
export var Topic = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-8"
})(_templateObject8(), fonts.regular, pxToCssFont(16, 22), colors.$262626);
export var TopicLarge = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-9"
})(_templateObject9(), fonts.regular, pxToCssFont(18, 24), colors.$262626);
export var Creator = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-10"
})(_templateObject10(), fonts.regular, pxToCssFont(16, 22), colors.$7e7e7e);
export var CreatorLarge = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-11"
})(_templateObject11(), fonts.regular, pxToCssFont(18, 24), colors.$7e7e7e);
export var PillElement = styled.div.withConfig({
    componentId: "sc-5e4b8d3b-12"
})(_templateObject12());
