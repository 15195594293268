import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  .overlay {\n    position: absolute;\n    height: inherit;\n    width: inherit;\n    display: flex;\n    background:\n      linear-gradient(0deg, rgba(0, 0, 0, 0.4) 12.57%, rgba(0, 0, 0, 0) 50%), linear-gradient(180deg, rgba(0, 0, 0, 0.4) 11.46%, rgba(0, 0, 0, 0) 54.79%);\n  },\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var ContainerStyled = styled.div.withConfig({
    componentId: "sc-756b130e-0"
})(_templateObject());
