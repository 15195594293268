var DEFAULT_SIZE = {
    default: {
        width: 405,
        height: 600
    },
    mobile: {
        width: 327,
        height: 480
    },
    tablet: {
        width: 327,
        height: 480
    }
};
var getSlideSize = function(isMobile, isTablet) {
    if (isTablet) return DEFAULT_SIZE.tablet || DEFAULT_SIZE.mobile;
    return isMobile ? DEFAULT_SIZE.mobile : DEFAULT_SIZE.default;
};
export { getSlideSize };
