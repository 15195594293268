import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  border-radius: 15px;\n  margin: 0 auto;\n  text-align: center;\n  box-sizing: border-box;\n  color: ",
        ";\n  width: fit-content;\n  // background: lightgray -559.748px -0.989px / 530.809% 142.365% no-repeat;\n\n  @media (min-width: ",
        ") {\n    // margin: 0 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    // margin: 0 80px;\n    // width: 1280px;\n    // background: lightgray -90.989px -7.584px / 134.803% 121.78% no-repeat;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: grid;\n  grid-template-columns: repeat(1, 1fr); // MOBILE\n  justify-items: center;\n  grid-gap: 24px;\n\n  @media (min-width: ",
        ") {\n    grid-template-columns: repeat(2, 1fr); // TABLET\n    grid-gap: 32px;\n  }\n  @media (min-width: ",
        ") {\n    grid-template-columns: repeat(4, 1fr); // DESKTOP\n    grid-gap: 48px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  background-color: transparent;\n  color: ",
        ";\n}\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  box-sizing: border-box;\n  height: 32px;\n  @media (min-width: ",
        ") {\n    height: 40px;\n  }\n  @media (min-width: ",
        ") {\n    height: 50px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  box-sizing: border-box;\n  height: 48px;\n  @media (min-width: ",
        ") {\n    height: 48px;\n  }\n  @media (min-width: ",
        ") {\n    height: 64px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin-top: 32px;\n  margin-bottom: 56px;\n  z-index: 2;\n  text-align: center;\n  button {\n    width: 200px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-style: normal;\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n  margin: 0;\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFontSize } from "@vfit/shared/themes";
export var SearchContainer = styled.div.withConfig({
    componentId: "sc-f14d162a-0"
})(_templateObject(), colors.$ffffff, breakpoints.tablet, breakpoints.desktop);
export var GridContainer = styled.div.withConfig({
    componentId: "sc-f14d162a-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.bigDesktop);
export var ItemContainer = styled.div.withConfig({
    componentId: "sc-f14d162a-2"
})(_templateObject2(), colors.$0d0d0d);
export var Divider = styled.div.withConfig({
    componentId: "sc-f14d162a-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.bigDesktop);
export var DividerGrid = styled.div.withConfig({
    componentId: "sc-f14d162a-4"
})(_templateObject4(), breakpoints.tablet, breakpoints.bigDesktop);
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-f14d162a-5"
})(_templateObject5());
export var TitleH3 = styled.div.withConfig({
    componentId: "sc-f14d162a-6"
})(_templateObject6(), fonts.exbold, pxToCssFontSize(30), colors.$ffffff, breakpoints.tablet, pxToCssFontSize(30), breakpoints.bigDesktop, pxToCssFontSize(42));
