var DEFAULT_SIZE = {
    default: {
        width: 405,
        height: 720
    },
    mobile: {
        width: "100%",
        height: "100%"
    },
    tablet: {
        width: 352,
        height: 626
    }
};
var getStorySize = function(isMobile, isTablet) {
    if (isTablet) return DEFAULT_SIZE.tablet || DEFAULT_SIZE.mobile;
    return isMobile ? DEFAULT_SIZE.mobile : DEFAULT_SIZE.default;
};
export { getStorySize };
