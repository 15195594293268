import image from "./Image/Image";
import video from "./Video/Video";
import defaultRenderer from "./Default/Default";
import autoplayContent from "./AutoPlayContent/AutoPlayContent";
export var renderers = [
    image,
    video,
    autoplayContent,
    defaultRenderer
];
