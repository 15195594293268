import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  .story {\n    display: flex;\n    position: relative;\n    overflow: hidden;\n  },\n  .storyContent {\n  img {\n    width: auto;\n    max-width: 100%;\n    max-height: 100%;\n    margin: auto;\n  }\n  },\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var ImageStyled = styled.div.withConfig({
    componentId: "sc-ee250f1a-0"
})(_templateObject());
