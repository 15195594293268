import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n\n  .text {\n    display: flex;\n    flex-direction: column;\n    // shadow\n    // filter: drop-shadow(0 0px 3px rgba(0, 0, 0, 0.9));\n\n    .heading {\n      font-family: ",
        ";\n      font-weight: 400;\n      ",
        ";\n      color: ",
        ";\n      margin: 0;\n      margin-bottom: 2px;\n    }\n\n    .subheading {\n      font-family: ",
        ";\n      font-weight: 700;\n      ",
        ";\n      color: ",
        ";\n      margin: 0;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var HeaderStyled = styled.div.withConfig({
    componentId: "sc-26452f97-0"
})(_templateObject(), fonts.regular, pxToCssFont(16, 22), colors.$ffffff, fonts.regular, pxToCssFont(22, 32), colors.$ffffff);
