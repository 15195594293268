import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { computeDatalayer, trackOptions } from "./datalayer";
import { shouldTrack, retrieveBasePageName } from "./utils";
import { LoggerInstance } from "../logger";
var isDevLocal = "production" === "development";
var track = function(datalayer, trackingType) {
    var ref, ref1;
    if (trackingType !== "link") {
        window.utag_data = datalayer;
    }
    if (isDevLocal) {
        LoggerInstance.debug("Tracking datalayer --> ", datalayer);
    } else if (trackingType === "link" && ((ref = window.utag) === null || ref === void 0 ? void 0 : ref.link)) {
        var ref2;
        (ref2 = window.utag) === null || ref2 === void 0 ? void 0 : ref2.link(datalayer);
    } else if ((ref1 = window.utag) === null || ref1 === void 0 ? void 0 : ref1.view) {
        window.utag.view(datalayer);
    } else {
        document.addEventListener("utag_ready", function() {
            var ref;
            if ((ref = window.utag) === null || ref === void 0 ? void 0 : ref.view) window.utag.view(datalayer);
        }, {
            once: true
        });
    }
    window.utag_last_tracked_data = datalayer;
};
var useTracking = function(param) {
    var event = param.event, event_label = param.event_label, opts = param.opts, pageProduct = param.pageProduct, cartProduct = param.cartProduct, visitorTrackingOpts = param.visitorTrackingOpts, disableInitialTrack = param.disableInitialTrack;
    var ref = useState(false), tracked = ref[0], setTracked = ref[1];
    var ref1 = useState(null), datalayer = ref1[0], setDatalayer = ref1[1];
    var trackViewRef = useRef();
    var trackView = function(param) {
        var event_name = param.event_name, event_label_track = param.event_label_track, event_category = param.event_category, event_action = param.event_action, page_error = param.page_error, page_error_code = param.page_error_code, page_type = param.page_type, page_name_override = param.page_name_override, form_error = param.form_error, tracking_type = param.tracking_type, checkout_consents = param.checkout_consents, journey_name = param.journey_name, journey_type = param.journey_type, page_section = param.page_section, page_subsection = param.page_subsection, page_browser_system = param.page_browser_system;
        var ref;
        var basePageName = retrieveBasePageName(cartProduct, Array.isArray(pageProduct === null || pageProduct === void 0 ? void 0 : pageProduct.product_name) ? (pageProduct === null || pageProduct === void 0 ? void 0 : (ref = pageProduct.product_name) === null || ref === void 0 ? void 0 : ref[0]) || "" : (pageProduct === null || pageProduct === void 0 ? void 0 : pageProduct.product_name) || "");
        var pageName = "".concat(basePageName, ":").concat(tracking_type === "link" ? page_name_override || event_label || event[0] : page_name_override || event_label_track || event_name);
        var linkName = tracking_type === "link" && "".concat(basePageName, ":").concat(event_label_track || event_name);
        var datalayerTrackView = _object_spread(_object_spread_props(_object_spread({}, datalayer), {
            page_name: pageName
        }), event_name && {
            event_name: [
                event_name
            ]
        }, linkName && {
            link_name: linkName
        }, event_label_track && {
            event_label: event_label_track
        }, event_category && {
            event_category: event_category
        }, event_action && {
            event_action: event_action
        }, page_error && {
            page_error: page_error
        }, page_error_code && {
            page_error_code: page_error_code
        }, page_type && {
            page_type: page_type
        }, form_error && {
            form_error: form_error
        }, checkout_consents && {
            checkout_consents: checkout_consents
        }, journey_name && {
            journey_name: journey_name
        }, journey_type && {
            journey_type: journey_type
        }, page_section && {
            page_section: page_section
        }, page_subsection && {
            page_subsection: page_subsection
        }, page_browser_system && {
            page_browser_system: page_browser_system
        });
        track(datalayerTrackView, tracking_type);
    };
    var trackWithNewDatalayer = useCallback(function(newDatalayer) {
        var tempDatalayer = computeDatalayer(newDatalayer.event, newDatalayer.event_label, newDatalayer.opts, newDatalayer.pageProduct, newDatalayer.cartProduct, newDatalayer.visitorTrackingOpts);
        track(tempDatalayer);
        if (!newDatalayer.notOverrideDL) setDatalayer(tempDatalayer);
    }, []);
    useEffect(function() {
        if (!tracked && datalayer && !disableInitialTrack && shouldTrack(event[0])) {
            track(datalayer);
            setTracked(true);
        }
        if (datalayer) trackViewRef.current = trackView;
    }, [
        datalayer
    ]);
    useEffect(function() {
        setDatalayer(computeDatalayer(event, event_label, opts, pageProduct, cartProduct, visitorTrackingOpts));
    }, []);
    return {
        trackView: function(track_view) {
            var ref;
            return trackViewRef === null || trackViewRef === void 0 ? void 0 : (ref = trackViewRef.current) === null || ref === void 0 ? void 0 : ref.call(trackViewRef, track_view);
        },
        trackWithNewDatalayer: trackWithNewDatalayer
    };
};
export default useTracking;
export var tracking = function(datalayer, trackingType) {
    var ref;
    var trackingData = _object_spread({}, trackOptions(datalayer.event_name, datalayer.event_label, undefined, Array.isArray(datalayer.product_name) ? ((ref = datalayer.product_name) === null || ref === void 0 ? void 0 : ref[0]) || "" : datalayer.product_name || ""), datalayer);
    track(trackingData, trackingType);
    return trackingData;
};
