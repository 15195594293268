import { useMediaQuery } from "@vfit/shared/hooks";
import { colors } from "@vfit/shared/themes";
import { useMemo } from "react";
import { useCta } from "../../cta.hook";
export var useButton = function(param) {
    var _href = param._href, _onClick = param._onClick, _onMouseEnter = param._onMouseEnter, _onMouseLeave = param._onMouseLeave, disabled = param.disabled, size = param.size, variant = param.variant;
    var ref = useCta({
        _href: _href,
        _onClick: _onClick,
        _onMouseEnter: _onMouseEnter,
        _onMouseLeave: _onMouseLeave,
        disabled: disabled
    }), href = ref.href, isHover = ref.isHover, onClick = ref.onClick, onMouseEnter = ref.onMouseEnter, onMouseLeave = ref.onMouseLeave;
    var isDesktop = useMediaQuery("desktop", "min");
    var color = useMemo(function() {
        switch(true){
            case disabled && (variant === "primary" || variant === "mva:primary" || variant === "mva:secondary"):
            case variant === "primary":
            case variant === "mva:primary":
            case variant === "mva:secondary":
                return colors.$ffffff;
            case disabled && variant === "mva:alt1":
                return colors.$9a9a9a;
            case disabled:
                return colors.$bebebe;
            case variant === "mva:alt1":
                return !isHover ? colors.$0d0d0d : colors.$ffffff;
            case variant === "tertiary":
                return !isHover ? colors.$262626 : colors.$ffffff;
            case variant === "secondary":
            default:
                return colors.$262626;
        }
    }, [
        disabled,
        isHover,
        variant
    ]);
    var height = useMemo(function() {
        switch(size){
            case "big":
                return 16;
            case "medium":
                return 11;
            case "responsive":
                return !isDesktop ? 11 : 15;
            case "small":
            default:
                return 11;
        }
    }, [
        isDesktop,
        size
    ]);
    return {
        color: color,
        height: height,
        href: href,
        onClick: onClick,
        onMouseEnter: onMouseEnter,
        onMouseLeave: onMouseLeave
    };
};
