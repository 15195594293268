import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  b,\n  strong {\n    ",
        ";\n  }\n\n  > * {\n    &:first-child {\n      margin-top: 0;\n    }\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n      color: ",
        ";\n    "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n    "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n    "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n    "
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n      ",
        "\n    "
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n      flex-direction: row;\n      justify-content: flex-end;\n    "
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n      position: static;\n      align-items: center;\n      margin: ",
        ";\n    "
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n      gap: ",
        ";\n\n      @media (min-width: ",
        ") {\n        max-width: ",
        ";\n      }\n\n      @media (min-width: ",
        ") {\n        max-width: ",
        ";\n      }\n    "
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n      gap: ",
        ";\n\n      @media (min-width: ",
        ") {\n        max-width: ",
        ";\n      }\n\n      @media (min-width: ",
        ") {\n        max-width: ",
        ";\n      }\n    "
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-between;\n  text-align: center;\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100%;\n\n  @media (min-width: ",
        ") {\n    position: static;\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-bottom: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: ",
        ";\n  }\n\n  ",
        "\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  ",
        "\n"
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
function _templateObject16() {
    var data = _tagged_template_literal([
        "\n  bottom: 0;\n  flex-direction: column;\n  font-family: ",
        ";\n  display: flex;\n  width: 100%;\n  // position: ",
        ";\n  gap: ",
        ";\n\n  button {\n    width: 100%;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n\n    button {\n      min-width: ",
        ";\n      max-width: min-content;\n      padding: ",
        ";\n    }\n\n    ",
        "\n  }\n"
    ]);
    _templateObject16 = function _templateObject16() {
        return data;
    };
    return data;
}
function _templateObject17() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n"
    ]);
    _templateObject17 = function _templateObject17() {
        return data;
    };
    return data;
}
function _templateObject18() {
    var data = _tagged_template_literal([
        "\n  height: ",
        ";\n  width: ",
        ";\n"
    ]);
    _templateObject18 = function _templateObject18() {
        return data;
    };
    return data;
}
function _templateObject19() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: ",
        ";\n  margin: ",
        ";\n\n  @media (min-width: ",
        ") {\n    gap: ",
        ";\n  }\n\n  ",
        "\n"
    ]);
    _templateObject19 = function _templateObject19() {
        return data;
    };
    return data;
}
function _templateObject20() {
    var data = _tagged_template_literal([
        "\n  overflow: ",
        ";\n"
    ]);
    _templateObject20 = function _templateObject20() {
        return data;
    };
    return data;
}
import styled, { css } from "styled-components";
import { breakpoints, colors, cssfonts, fonts, getTypography, pxToRem } from "@vfit/shared/themes";
var normalize = css(_templateObject(), cssfonts.exbold);
var variants = {
    title: {
        mva: css(_templateObject1(), getTypography("h3.bold")),
        sunrise: css(_templateObject2(), getTypography("h2.extrabold"), colors.$e60000)
    },
    subtitle: {
        mva: css(_templateObject3(), getTypography("h5.bold")),
        sunrise: css(_templateObject4(), getTypography("h3.extrabold"))
    },
    description: {
        mva: css(_templateObject5(), getTypography("body2.regular")),
        sunrise: css(_templateObject6(), getTypography("h4.light"))
    },
    insideButtonContainer: {
        mva: css(_templateObject7()),
        sunrise: css(_templateObject8(), pxToRem(113, 0, 0))
    },
    bodyWrap: {
        mva: css(_templateObject9(), pxToRem(16), breakpoints.tablet, pxToRem(400), breakpoints.desktop, pxToRem(440)),
        sunrise: css(_templateObject10(), pxToRem(24), breakpoints.tablet, pxToRem(520), breakpoints.desktop, pxToRem(840))
    }
};
export var Container = styled.div.withConfig({
    componentId: "sc-451a0d5b-0"
})(_templateObject11(), colors.$262626);
export var MessageDiv = styled.div.withConfig({
    componentId: "sc-451a0d5b-1"
})(_templateObject12(), breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-451a0d5b-2"
})(_templateObject13(), normalize, pxToRem(24), breakpoints.tablet, pxToRem(20), breakpoints.desktop, pxToRem(30), function(param) {
    var variant = param.variant;
    return variant ? variants.title[variant] : "";
});
export var Subtitle = styled.div.withConfig({
    componentId: "sc-451a0d5b-3"
})(_templateObject14(), normalize, function(param) {
    var variant = param.variant;
    return variant ? variants.subtitle[variant] : "";
});
export var Description = styled.div.withConfig({
    componentId: "sc-451a0d5b-4"
})(_templateObject15(), normalize, function(param) {
    var variant = param.variant;
    return variant ? variants.description[variant] : "";
});
export var InsideButtonContainer = styled.div.withConfig({
    componentId: "sc-451a0d5b-5"
})(_templateObject16(), fonts.regular, function(param) {
    var isButtonSticky = param.isButtonSticky;
    return isButtonSticky ? "sticky" : "static";
}, pxToRem(16), breakpoints.tablet, pxToRem(400), pxToRem(0, 105), function(param) {
    var variant = param.variant;
    return variant ? variants.insideButtonContainer[variant] : "";
});
export var ImageContainer = styled.div.withConfig({
    componentId: "sc-451a0d5b-6"
})(_templateObject17());
export var ImageWrapper = styled.div.withConfig({
    componentId: "sc-451a0d5b-7"
})(_templateObject18(), pxToRem(120), pxToRem(120));
export var BodyWrap = styled.div.withConfig({
    componentId: "sc-451a0d5b-8"
})(_templateObject19(), function(param) {
    var descriptionOnTop = param.descriptionOnTop;
    return descriptionOnTop ? "column-reverse" : "column";
}, pxToRem(24, 0, 0), breakpoints.tablet, pxToRem(16), function(param) {
    var variant = param.variant;
    return variant ? variants.bodyWrap[variant] : "";
});
export var TopContainer = styled.div.withConfig({
    componentId: "sc-451a0d5b-9"
})(_templateObject20(), function(param) {
    var isButtonSticky = param.isButtonSticky;
    return isButtonSticky ? "auto" : "unset";
});
