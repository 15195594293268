import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  .storyContent {\n    width: auto;\n    max-width: 100%;\n    max-height: 100%;\n    margin: auto;\n  },\n  .videoContainer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    video {\n      object-fit: cover;\n      width: 100%;\n      height: 100vh\n    }\n  },\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var VideoStyled = styled.div.withConfig({
    componentId: "sc-5b2a1967-0"
})(_templateObject());
