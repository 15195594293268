import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
var getRenderer = function(story, renderers) {
    var probable = renderers.map(function(r) {
        return _object_spread_props(_object_spread({}, r), {
            testerResult: r.tester(story)
        });
    }).filter(function(r) {
        return r.testerResult.condition;
    });
    probable.sort(function(a, b) {
        return b.testerResult.priority - a.testerResult.priority;
    });
    return probable[0].renderer;
};
var generateStories = function(stories, renderers) {
    return stories.map(function(s) {
        var story = {};
        if (typeof s === "string") {
            story.url = s;
            story.type = "image";
        } else if (typeof s === "object") {
            story = Object.assign(story, s);
        }
        var renderer = getRenderer(story, renderers);
        story.originalContent = story.content;
        story.content = renderer;
        return story;
    });
};
export { getRenderer, generateStories };
