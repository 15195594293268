import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 4;\n  background-color: #000000c4;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  top: ",
        "px;\n  border-top-left-radius: ",
        "px;\n  border-top-right-radius: ",
        "px;\n  background-color: ",
        ";\n  overflow: hidden;\n\n  .closing-icon {\n    float: right;\n    margin-top: 21.5px;\n    margin-right: 21.5px;\n\n    &:hover {\n      cursor: pointer;\n    }\n  }\n\n  a {\n    color: ",
        " !important;\n    font-weight: 700 !important;\n\n    :visited {\n      color: ",
        " !important;\n    }\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    left: 50%;\n    top: 50%;\n    border-radius: 20px;\n    width: 842px;\n    transform: translate(-50%, -50%);\n    ",
        "\n\n    .closing-icon {\n      margin-top: 29.5px;\n      margin-right: 29.5px;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  height: calc(100% - 66px);\n  margin: 48px 16px 32px 44px;\n  overflow: auto;\n  scrollbar-width: 3px;\n\n  ::-webkit-scrollbar {\n    width: 3px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: calc(100% - 90px);\n    margin: 60px 16px 64px 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    height: calc(100% - 124px);\n    margin: 72px 32px 64px 109px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  background-color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    width: 62.2%;\n  }\n\n  .closing-icon:hover {\n    cursor: pointer;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: space-between;\n  padding: 25px;\n  border-bottom: 1px solid #5e5e5e;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: space-between;\n  padding: 30px 25px 25px;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin-top: auto;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 25px;\n\n  button {\n    text-transform: uppercase;\n    max-width: 300px;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  padding-right: 25px;\n  height: 100%;\n\n  @media (min-width: ",
        ") {\n    padding-right: 21px;\n  }\n\n  @media (min-width: ",
        ") {\n    padding-right: 74px;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors } from "@vfit/shared/themes";
export var Background = styled.div.withConfig({
    componentId: "sc-9baa1b83-0"
})(_templateObject());
export var Container = styled.div.withConfig({
    componentId: "sc-9baa1b83-1"
})(_templateObject1(), function(props) {
    return props.withBorderRadius ? "66" : "0";
}, function(props) {
    return props.withBorderRadius ? "20" : "0";
}, function(props) {
    return props.withBorderRadius ? "20" : "0";
}, colors.$ffffff, colors.$262626, colors.$262626, breakpoints.tablet, function(props) {
    return !props.tabletMode ? "\n          left: 50%;\n          top: 50%;\n          transform: translate(-50%, -50%);\n          width: 62.2%;\n          border-radius: 20px;\n          height: ".// eslint-disable-next-line no-nested-ternary
    concat(props.maxHeight ? "100%" : "auto", ";\n          max-height: ").concat(props.maxHeight ? typeof props.maxHeight === "string" ? props.maxHeight : "".concat(props.maxHeight, "px") : "100%", ";\n           .closing-icon {\n            margin-top: 29.5px;\n            margin-right: 21.5px;\n          }\n        ") : "margin: 0 90px;";
}, breakpoints.desktop, function(props) {
    return "\n            height: ".// eslint-disable-next-line no-nested-ternary
    concat(props.maxHeight ? "100%" : "auto", ";\n            max-height: ").concat(props.maxHeight ? typeof props.maxHeight === "string" ? props.maxHeight : "".concat(props.maxHeight, "px") : "100%", ";\n        ");
});
export var ModalContent = styled.div.withConfig({
    componentId: "sc-9baa1b83-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop);
// TODO: wip FIGMA modal error styles
export var ContainerModalError = styled.div.withConfig({
    componentId: "sc-9baa1b83-3"
})(_templateObject3(), colors.$ffffff, breakpoints.tablet);
export var ModalErrorHeader = styled.div.withConfig({
    componentId: "sc-9baa1b83-4"
})(_templateObject4());
export var ModalErrorContent = styled.div.withConfig({
    componentId: "sc-9baa1b83-5"
})(_templateObject5());
export var ModalErrorFooter = styled.div.withConfig({
    componentId: "sc-9baa1b83-6"
})(_templateObject6());
export var Padder = styled.div.withConfig({
    componentId: "sc-9baa1b83-7"
})(_templateObject7(), breakpoints.tablet, breakpoints.desktop);
