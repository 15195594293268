/* eslint-disable import/no-dynamic-require */ /* eslint-disable global-require */ import { getHome } from "./getHome";
import { getLivePreviewMedia } from "./checkLivePreview";
var PLACEHOLDER_IMAGE = "https://www.vodafone.it/portal/assets/img/menu/vodafone-logo.png";
/**
 * @param video
 * @param videoMobile
 * @param isDesktop
 * @param isTablet
 * @param isMobile
 */ var requireVideo = function(video, videoMobile, isDesktop, isTablet, isMobile) {
    var ref, ref1;
    var videoUrl = isMobile ? videoMobile || video : video;
    var livePreviewMedia = getLivePreviewMedia(videoUrl, "videos");
    if (livePreviewMedia) return livePreviewMedia;
    var splitExt = videoUrl === null || videoUrl === void 0 ? void 0 : videoUrl.split(".");
    if (!splitExt) return "";
    var haveGoodNetwork = (navigator === null || navigator === void 0 ? void 0 : (ref = navigator["connection"]) === null || ref === void 0 ? void 0 : ref["downlink"]) ? (navigator === null || navigator === void 0 ? void 0 : (ref1 = navigator["connection"]) === null || ref1 === void 0 ? void 0 : ref1["downlink"]) >= 5 : true;
    var quality = haveGoodNetwork ? "_medium" : "_low";
    var videoSplitted = "".concat(splitExt[0]).concat(quality);
    return "".concat(getHome(), "/videos/").concat(videoSplitted, ".").concat(splitExt[1]);
};
var requireStaticImageComponent = function(image, imageMobile, isMobile) {
    try {
        // eslint-disable-next-line import/no-dynamic-require
        return require("../../../../../../".concat(process.env["NX_MEDIA_ROOT"], "/public/images/").concat(isMobile ? imageMobile : image));
    } catch (errorFirstImage) {
        try {
            console.log("imageBackground ERROR FIRST IMG", errorFirstImage);
            // eslint-disable-next-line import/no-dynamic-require
            return require("../../../../../../".concat(process.env["NX_MEDIA_ROOT"], "/public/images/").concat(image));
        } catch (errorSecondImage) {
            console.log("imageBackground errorSecondImage ", errorSecondImage);
            return PLACEHOLDER_IMAGE;
        }
    }
};
var getFileExtension = function(filename) {
    return filename.substring(filename.lastIndexOf(".") + 1, filename.length).toLowerCase();
};
var getObjImage = function(filename, ext) {
    try {
        if (ext === "gif" || ext === "webp" || ext === "json") {
            return {
                src: require("../../../../../../".concat(process.env["NX_MEDIA_ROOT"], "/public/images/").concat(filename))
            };
        }
        if (ext === "svg") {
            return {
                src: require("../../../../../../".concat(process.env["NX_MEDIA_ROOT"], "/public/images/").concat(filename, "?url"))
            };
        }
        return require("../../../../../../".concat(process.env["NX_MEDIA_ROOT"], "/public/images/").concat(filename));
    } catch (e) {
        var livePreviewUrl = getLivePreviewMedia(filename, "images");
        if (livePreviewUrl) return livePreviewUrl;
        return {
            src: PLACEHOLDER_IMAGE,
            srcSet: ""
        };
    }
};
var requireStaticImageAtomComponent = function(image, imageMobile, isMobile) {
    var filename = isMobile ? imageMobile || image : image;
    var ext = getFileExtension(filename);
    return getObjImage(filename, ext);
};
export { requireVideo, requireStaticImageComponent, requireStaticImageAtomComponent };
