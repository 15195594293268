import { IFrameService } from "./lib/iframeManager";
import { ILeadModalService } from "./lib/leadModalManager";
import { handleEnv } from "./lib/handleEnv";
import { LoggerInstance } from "./lib/logger";
import { cmsClient, HttpClient, nextClient, serverlessClient, awsConsumerClient, headersNext, prefixUrl, mode, credentials, dxlClientJourneyApp, dxlClient } from "./lib/fetcher";
import { DxlError, errorMessages, delay } from "./lib/fetcher/utils";
import { ModalIframeWrapper } from "./lib/iframeManager/context";
import { ModalLeadWrapper } from "./lib/leadModalManager/context";
import { handleUseQuery, handleUseQueryes, resetData } from "./lib/ReactQuery";
import QueryProvider from "./lib/query";
import OneTrustCookieConsents from "./lib/tracking/oneTrustCookieConsents";
import { ErrorService } from "./lib/errorManager";
import { ModalErrorWrapper } from "./lib/errorManager/context";
import { TrackingContextProvider, useTrackingProvider } from "./lib/tracking/provider";
export var iFrameManager = new IFrameService();
export var leadModalManager = new ILeadModalService();
export var errorManager = new ErrorService();
/** tracking folder */ export { default as useTracking } from "./lib/tracking/use-tracking";
export { tracking } from "./lib/tracking/use-tracking";
export * from "./lib/tracking/models";
export * from "./lib/tracking/utils";
export default QueryProvider;
export { TrackingContextProvider, LoggerInstance, cmsClient, nextClient, dxlClientJourneyApp, dxlClient, serverlessClient, awsConsumerClient, HttpClient, handleEnv, ModalIframeWrapper, ModalLeadWrapper, headersNext, prefixUrl, mode, credentials, ModalErrorWrapper, ErrorService, handleUseQuery, handleUseQueryes, resetData, OneTrustCookieConsents, DxlError, errorMessages, delay, useTrackingProvider };
