import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  left: 24px;\n  right: 24px;\n  top: 40px;\n  z-index: 19;\n  max-width: 85%;\n  @media (min-width: ",
        ") {\n    max-width: inherit;\n  }\n  @media (min-width: ",
        ") {\n    max-width: inherit;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var WithHeaderContainer = styled.div.withConfig({
    componentId: "sc-488a7a66-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
