import { IActionStyle, IActionType } from "@vfit/shared/models";
import { openPopup } from "./openPopup";
import { openLeadModal } from "./openLeadModal";
import { manageErrorLeadPlatform, manageSuccessLeadPlatform } from "./cmsForm";
export var getVariantByActionStyle = function(actionStyle) {
    if (!actionStyle) return "primary";
    switch(actionStyle){
        case IActionStyle.PRIMARY:
            return "primary";
        case IActionStyle.SECONDARY:
            return "secondary";
        case IActionStyle.TERTIARY:
            return "tertiary";
        default:
            return "primary";
    }
};
export var getButtonActionByActionType = function(actionSlide, push, queryClient) {
    if (!actionSlide) return;
    var buttonType = (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.type) ? +actionSlide.type : 0;
    switch(buttonType){
        case IActionType.CUSTOM_URL:
            if (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.url) {
                window.open(actionSlide.url, (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.isBlank) === "true" ? "_blank" : "_self");
            }
            break;
        case IActionType.COVERAGE_TOOL:
            break;
        case IActionType.CALL_ME_NOW:
            if (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.callMeNow) openPopup(actionSlide.callMeNow);
            else if (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.url) openPopup(actionSlide.url);
            break;
        case IActionType.LEAD_MODAL:
            if (queryClient) {
                var allLeads = queryClient === null || queryClient === void 0 ? void 0 : queryClient.getQueryData("getAllLeads");
                // form name
                var ref = (actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.localUrl) || "";
                var forms = allLeads === null || allLeads === void 0 ? void 0 : allLeads[ref].forms;
                if (forms) {
                    openLeadModal({
                        form: forms,
                        onSuccess: function(submitOutput) {
                            return manageSuccessLeadPlatform(submitOutput, push, queryClient);
                        },
                        onError: function(submitOutput, errorCmsApi) {
                            return manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient);
                        }
                    });
                }
            }
            break;
        case IActionType.LOCAL_URL:
            if ((actionSlide === null || actionSlide === void 0 ? void 0 : actionSlide.localUrl) && push) push("/".concat(actionSlide.localUrl), undefined, {
                shallow: true
            });
            break;
        default:
            break;
    }
};
/**
 * Check if string is JSON
 * @param str
 */ export var isJSON = function(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};
