import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  height: ",
        ";\n  width: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    height: ",
        ";\n    width: ",
        ";\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n  "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n    height: ",
        ";\n    width: ",
        ";\n  "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n    ",
        "\n\n    @media (min-width: ",
        ") {\n      height: ",
        ";\n      width: ",
        ";\n    }\n  "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  height: ",
        ";\n  padding: ",
        ";\n  width: 100%;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  border-radius: 50%;\n  ",
        "\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n    background-color: ",
        ";\n    border-color: ",
        ";\n    color: ",
        ";\n\n    &:hover {\n      background-color: ",
        ";\n      border-color: ",
        ";\n    }\n\n    &:active {\n      background-color: ",
        ";\n      border-color: ",
        ";\n    }\n  "
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n    background-color: ",
        ";\n    border: none;\n    color: ",
        ";\n\n    &:hover {\n      background-color: ",
        ";\n    }\n\n    &:active {\n      background-color: ",
        ";\n    }\n  "
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n    background-color: 'transparent';\n    border-color: ",
        ";\n    color: ",
        ";\n\n    &:hover {\n      background-color: ",
        ";\n      color: ",
        ";\n    }\n\n    &:active {\n      background-color: ",
        ";\n      border-color: ",
        ";\n    }\n  "
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n    background-color: ",
        ";\n    border-color: ",
        ";\n    color: ",
        ";\n\n    &:hover {\n      background-color: ",
        ";\n      border-color: ",
        ";\n    }\n\n    &:active {\n      background-color: ",
        ";\n      border-color: ",
        ";\n    }\n  "
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n    background-color: ",
        ";\n    border-color: ",
        ";\n    color: ",
        ";\n    display: 'flex';\n\n    &:hover {\n      background-color: ",
        ";\n      border-color: ",
        ";\n    }\n\n    &:active {\n      background-color: ",
        ";\n      border-color: ",
        ";\n    }\n  "
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n    background-color: 'transparent';\n    border-color: ",
        ";\n    color: ",
        ";\n\n    &:hover {\n      background-color: ",
        ";\n      border-color: ",
        ";\n      color: ",
        ";\n    }\n\n    &:active {\n      background-color: ",
        ";\n      border-color: ",
        ";\n    }\n  "
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n    background-color: ",
        ";\n    border-color: ",
        ";\n    color: ",
        ";\n  "
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n    background-color: ",
        ";\n    color: ",
        ";\n  "
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
function _templateObject16() {
    var data = _tagged_template_literal([
        "\n    background-color: transparent;\n    border-color: ",
        ";\n    color: ",
        ";\n  "
    ]);
    _templateObject16 = function _templateObject16() {
        return data;
    };
    return data;
}
function _templateObject17() {
    var data = _tagged_template_literal([
        "\n    background-color: ",
        ";\n    border-color: ",
        ";\n    color: ",
        ";\n  "
    ]);
    _templateObject17 = function _templateObject17() {
        return data;
    };
    return data;
}
function _templateObject18() {
    var data = _tagged_template_literal([
        "\n    background-color: ",
        ";\n    border-color: ",
        ";\n  "
    ]);
    _templateObject18 = function _templateObject18() {
        return data;
    };
    return data;
}
function _templateObject19() {
    var data = _tagged_template_literal([
        "\n    background-color: transparent;\n    border-color: ",
        ";\n    color: ",
        ";\n  "
    ]);
    _templateObject19 = function _templateObject19() {
        return data;
    };
    return data;
}
function _templateObject20() {
    var data = _tagged_template_literal([
        "\n  align-items: center;\n  border-radius: ",
        ";\n  border-style: solid;\n  border-width: ",
        ";\n  display: inline-flex;\n  gap: ",
        ";\n  justify-content: center;\n  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;\n  white-space: nowrap;\n  ",
        "\n  ",
        "\n  \n  &::selection {\n    background-color: transparent;\n  }\n\n  & svg * {\n    transition: stroke 0.3s ease, fill 0.3s ease;\n  }\n"
    ]);
    _templateObject20 = function _templateObject20() {
        return data;
    };
    return data;
}
function _templateObject21() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  cursor: not-allowed;\n  pointer-events: none;\n"
    ]);
    _templateObject21 = function _templateObject21() {
        return data;
    };
    return data;
}
import { breakpoints, colors, getTypography, pxToRem } from "@vfit/shared/themes";
import { css } from "styled-components";
var medium = css(_templateObject(), pxToRem(35), pxToRem(35));
var sizes = {
    small: css(_templateObject1(), pxToRem(26), pxToRem(26)),
    medium: css(_templateObject2(), medium),
    big: css(_templateObject3(), pxToRem(55), pxToRem(55)),
    responsive: css(_templateObject4(), medium, breakpoints.desktop, pxToRem(50), pxToRem(50))
};
var font = css(_templateObject5(), function(param) {
    var variant = param.variant;
    return (variant === null || variant === void 0 ? void 0 : variant.startsWith("mva")) ? getTypography("button1.regular") : getTypography("button2.bold");
});
var regular = css(_templateObject6(), font, pxToRem(45), pxToRem(10, 24));
var circle = css(_templateObject7(), function(param) {
    var size = param.size;
    return sizes[size || "small"];
});
var variants = {
    primary: css(_templateObject8(), colors.$e60000, colors.$e60000, colors.$ffffff, colors.$bd0000, colors.$bd0000, colors.$a10000, colors.$a10000),
    secondary: css(_templateObject9(), colors.$ffffff, colors.$262626, colors.$ffffff_60, colors.$ffffff_80),
    tertiary: css(_templateObject10(), colors.$262626, colors.$262626, colors.$262626, colors.$ffffff, colors.$7e7e7e, colors.$7e7e7e),
    "mva:primary": css(_templateObject11(), colors.$e60000, colors.$e60000, colors.$ffffff, colors.$c30000, colors.$c30000, colors.$a10000, colors.$a10000),
    "mva:secondary": css(_templateObject12(), colors.$262626, colors.$262626, colors.$ffffff, colors.$313131, colors.$313131, colors.$565656, colors.$565656),
    "mva:alt1": css(_templateObject13(), colors.$262626, colors.$262626, colors.$313131, colors.$313131, colors.$ffffff, colors.$565656, colors.$565656)
};
var disabledVariants = {
    primary: css(_templateObject14(), colors.$bebebe, colors.$bebebe, colors.$ffffff),
    secondary: css(_templateObject15(), colors.$ffffff_60, colors.$bebebe),
    tertiary: css(_templateObject16(), colors.$bebebe, colors.$bebebe),
    "mva:primary": css(_templateObject17(), colors.$b99898, colors.$b99898, colors.$ffffff),
    "mva:secondary": css(_templateObject18(), colors.$9a9a9a, colors.$9a9a9a),
    "mva:alt1": css(_templateObject19(), colors.$9a9a9a, colors.$9a9a9a)
};
export var common = css(_templateObject20(), function(param) {
    var variant = param.variant;
    return (variant === null || variant === void 0 ? void 0 : variant.startsWith("mva")) ? pxToRem(6) : pxToRem(30);
}, function(param) {
    var variant = param.variant;
    return (variant === null || variant === void 0 ? void 0 : variant.startsWith("mva")) ? "2px" : "1px";
}, pxToRem(8), function(param) {
    var variant = param.variant;
    return variants[variant || "primary"];
}, function(param) {
    var model = param.model;
    return model === "regular" ? regular : circle;
});
// cursor: not-allowed; is ignored because pointer-events: none;
// but pointer-events: none; is necessary to disable Link from Next
export var disabled = css(_templateObject21(), function(param) {
    var variant = param.variant;
    return disabledVariants[variant || "primary"];
});
