import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  border: 1px solid ",
        ";\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  border: 1px solid ",
        ";\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  border: 1px solid ",
        ";\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  border-radius: ",
        ";\n  list-style: none;\n  margin: 0;\n  overflow: hidden;\n  padding: ",
        ";\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  ",
        "\n  ",
        "\n  ",
        "\n\n  &:hover {\n    cursor: pointer;\n  }\n\n  @media (min-width: ",
        ") {\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
import { breakpoints, colors, getTypography, pxToRem } from "@vfit/shared/themes";
import styled, { css } from "styled-components";
/**
 * TEXT COLORS CSS DEFINITION
 */ var black_text = css(_templateObject(), colors.$0d0d0d);
var white_text = css(_templateObject1(), colors.$ffffff);
var grey_text = css(_templateObject2(), colors.$f2f2f2);
var opaque_text = css(_templateObject3(), colors.$ffffff_80);
/**
 * BACKGROUND COLORS CSS DEFINITION
 */ var black = css(_templateObject4(), colors.$0d0d0d);
var white = css(_templateObject5(), colors.$ffffff);
var grey = css(_templateObject6(), colors.$f2f2f2);
var opaque = css(_templateObject7(), colors.$ffffff_80);
var transparent = css(_templateObject8(), colors.$transparent);
/**
 * BORDER COLORS CSS DEFINITION
 */ var white_border = css(_templateObject9(), colors.$ffffff);
var grey_border = css(_templateObject10(), colors.$f2f2f2);
var opaque_border = css(_templateObject11(), colors.$ffffff_80);
var getBackgroundColor = function(variant, type, isSelected, isHover) {
    if (isHover && !isSelected) {
        if (!type || type === "default") {
            switch(variant){
                case "grey":
                    return white;
                case "opaque":
                    return white;
                default:
                    return black;
            }
        }
        return white;
    }
    if (!type || type === "default" || isSelected) {
        switch(variant){
            case "grey":
                return grey;
            case "opaque":
                return opaque;
            default:
                return white;
        }
    }
    return transparent;
};
var getTextColor = function(variant, type, isSelected, isHover) {
    if (isHover && !isSelected) {
        if (!type || type === "default") {
            if (variant === "white") {
                return white_text;
            }
        }
        switch(variant){
            case "grey":
                return white_text;
            case "opaque":
                return white_text;
            default:
                return black_text;
        }
    } else {
        if (!type || type === "default" || isSelected) {
            if (variant === "white") {
                return black_text;
            }
        }
        switch(variant){
            case "grey":
                return grey_text;
            case "opaque":
                return opaque_text;
            default:
                return white_text;
        }
    }
};
var getBorderColor = function(variant) {
    switch(variant){
        case "grey":
            return grey_border;
        case "opaque":
            return opaque_border;
        default:
            return white_border;
    }
};
export var Li = styled.li.withConfig({
    componentId: "sc-d7d1300b-0"
})(_templateObject12(), getTypography("tag.regular"), pxToRem(20), pxToRem(4, 16), function(param) {
    var variant = param.variant, type = param.type, isSelected = param.isSelected, isHover = param.isHover;
    return getBackgroundColor(variant, type, isSelected, isHover);
}, function(param) {
    var variant = param.variant;
    return getBorderColor(variant);
}, function(param) {
    var variant = param.variant, type = param.type, isSelected = param.isSelected, isHover = param.isHover;
    return getTextColor(variant, type, isSelected, isHover);
}, breakpoints.desktop, pxToRem(4, 16));
