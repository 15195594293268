import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  body {\n    overflow: hidden;\n  }\n\n  #sticky-offer-summary {\n    visibility: hidden;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled, { createGlobalStyle } from "styled-components";
export var OverlayGlobalStyle = createGlobalStyle(_templateObject());
export var WrapperOverrideStyle = styled.div.withConfig({
    componentId: "sc-2eed991-0"
})(_templateObject1(), function(props) {
    return props.wrapperId && "\n       div  #".concat(props.wrapperId, " {\n       padding: 18px;\n       max-height: 700px !important;\n      }");
});
