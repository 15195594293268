import { useQueryClient } from "react-query";
import { API } from "@vfit/shared/data-access";
export var CMS_APP_CONFIG = {
    apis: [
        {
            key: "getPageNavigation",
            api: API.CMS_GET_PAGE_NAVIGATION
        }, 
    ]
};
export var useAppCmsConfig = function(apiKey) {
    var apis = CMS_APP_CONFIG.apis;
    var allData = {};
    apis === null || apis === void 0 ? void 0 : apis.forEach(function(api) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var client = useQueryClient();
        var data = client.getQueryData(api.key, {
            exact: false
        });
        allData[api.api] = data;
    });
    return apiKey ? allData[apiKey] : allData;
};
